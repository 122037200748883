<template>
  <div class="container-fluid px-0">
    <b-card no-body>
      <b-card-header class="d-flex flex-row align-items-center">
        Performance
        <div class="d-flex flex-row flex-grow-1 justify-content-end">
          <b-button
            href="https://api.morgansolar.xyz/docs"
            target="_blank"
            size="sm"
            variant="primary"
          >API Docs</b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend>
              <b-form-select
                v-model="method"
                v-on:change="handleMethodChange"
                :plain="true"
                :options="['GET', 'POST']"
              ></b-form-select>
            </b-input-group-prepend>
            <b-form-input
              placeholder="URL"
              v-model="url"
              @keyup.enter.native="handleFetch"
              @keyup.native="handleUrlChange"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="handleFetch">Send</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <div>
          <h5>Request Options</h5>
          <div class="d-flex flex-row">
            <b-form-group label="Number of Requests" label-for="num-requests">
              <b-form-input
                id="num-requests"
                type="number"
                :min="1"
                :step="1"
                v-model="numRequests"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div>
          <h5>Query Parameters</h5>
          <b-row>
            <b-col md="12" lg="6">
              <div class="query mt-1" v-for="(query, index) in queries" :key="index">
                <b-form-input
                  placeholder="Key"
                  :value="query.key"
                  @input="handleInputChange($event, index, 'key')"
                  class="mr-1"
                  name="key"
                ></b-form-input>
                <b-form-input
                  placeholder="Value"
                  :value="query.value"
                  @input="handleInputChange($event, index, 'value')"
                  name="value"
                ></b-form-input>
              </div>
              <b-button variant="primary" @click="handleAdd" class="mt-2 mr-2">Add</b-button>
              <b-button
                v-if="queries.length > 0"
                variant="danger"
                @click="handleRemove"
                class="mt-2"
              >Remove</b-button>
            </b-col>
          </b-row>
        </div>

        <div v-if="method === 'POST'" class="body">
          <h5>Body</h5>
          <prism-editor language="js" :line-numbers="true" class="editor" v-model="body"></prism-editor>
        </div>

        <div class="mt-3">
          <div class="d-flex flex-row">
            <h5 class="mb-3 mr-3">Request Statistics</h5>
            <img v-if="loading" src="img/msi_spinner.gif" height="24" width="24" />
            <div class="flex-grow-1 d-flex flex-row justify-content-end my-2">
              <b-badge pill variant="danger" v-if="errors > 0">{{ errors }} Errors</b-badge>
            </div>
          </div>
          <b-table striped hover :fields="fields" :items="items"></b-table>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import 'prismjs';
import 'prismjs/themes/prism.css';
import PrismEditor from 'vue-prism-editor';
import 'vue-prism-editor/dist/VuePrismEditor.css';
import querystring from 'querystring';
import miniToastr from 'mini-toastr';
import * as math from 'mathjs';


miniToastr.init();

export default {
  name: 'performance',
  components: {
    PrismEditor
  },
  data() {
    return {
      url: 'https://api.morgansolar.xyz/api/v1/',
      method: 'GET',
      queries: [],
      body: '',
      numRequests: 1,
      fields: [
        {
          label: 'Measurement',
          key: 'measurement'
        },
        {
          label: 'Duration (ms)',
          key: 'duration'
        }
      ],
      items: [
        { measurement: 'Average', duration: 0 },
        { measurement: 'Min', duration: 0 },
        { measurement: 'Max', duration: 0 },
        { measurement: 'Standard Deviation', duration: 0 }
      ],
      loading: false,
      errors: 0
    };
  },
  methods: {
    handleMethodChange() {
      // When http method changes, reset state
      this.body = '';
    },
    handleAdd() {
      if (this.queries.length === 0) {
        this.url = `${this.url}?`;
      }

      this.queries.push({ key: `key${this.queries.length}`, value: '' });
      this.changeUrl();
    },
    handleRemove() {
      this.queries.pop();
      this.changeUrl();

      if (this.queries.length === 0) {
        this.url = this.url.slice(0, this.url.length - 1);
      }
    },
    handleUrlChange() {
      const queryIndex = this.url.indexOf('?');
      if (queryIndex === -1) {
        this.queries = [];
        return;
      }

      const queryParams = this.url.slice(queryIndex + 1);
      const arrayQueryParams = [];
      const queryObj = querystring.parse(queryParams);
      for (const [key, value] of Object.entries(queryObj)) {
        if (Array.isArray(value)) {
          for (const subval of value) {
            arrayQueryParams.push({ key, value: subval });
          }
        } else {
          arrayQueryParams.push({ key, value });
        }
      }

      this.queries = arrayQueryParams;
    },
    handleInputChange(input, index, type) {
      if (type === 'key') {
        if (input === '') return;
        this.queries.splice(index, 1, {
          key: input,
          value: this.queries[index].value
        });
      } else {
        this.queries.splice(index, 1, {
          key: this.queries[index].key,
          value: input
        });
      }

      this.changeUrl();
    },
    changeUrl() {
      const queryIndex = this.url.indexOf('?');
      const newUrl = this.url.slice(0, queryIndex + 1);

      const queryParams = this.queries
        .map((query) => {
          if (query.key) {
            return `${encodeURIComponent(query.key)}=${encodeURIComponent(
              query.value
            )}`;
          }

          return '';
        })
        .join('&');

      this.url = newUrl + queryParams;
    },
    handleFetch() {
      let { body } = this;
      const { method, numRequests } = this;
      this.loading = true;

      if (method === 'POST') {
        try {
          body = JSON.parse(body);
        } catch (ex) {
          miniToastr.error('The JSON you entered is invalid.', 'Invalid JSON');
          return;
        }
      }

      performance.clearMarks();
      performance.clearMeasures();
      this.errors = 0;

      const num = Math.round(numRequests);
      this.numRequests = num;

      const requests = [];
      for (let i = 0; i < num; i++) {
        requests.push(this.sendRequest(i, method, body));
      }

      Promise.all(requests)
        .then((values) => {
          values = values.filter(n => typeof n === 'number');

          let max = 0;
          let min = 0;
          let avg = 0;
          let std = 0;
          if (values.length > 0) {
            max = math.max(values);
            min = math.min(values);
            avg = math.mean(values);
            std = math.std(values);
          }

          this.items = [
            { measurement: 'Average', duration: math.round(avg) },
            { measurement: 'Min', duration: math.round(min) },
            { measurement: 'Max', duration: math.round(max) },
            { measurement: 'Standard Deviation', duration: math.round(std) }
          ];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendRequest(i, method, body) {
      return new Promise((resolve) => {
        let hasError = false;
        performance.mark(`request-${i + 1}-start`);

        this.$fetch(this.url, { method, body })
          .catch((err) => {
            this.errors++;
            console.error(err);
            hasError = true;
          })
          .finally(() => {
            performance.mark(`request-${i + 1}-end`);
            performance.measure(
              `request-${i + 1}-measure`,
              `request-${i + 1}-start`,
              `request-${i + 1}-end`
            );
            const { duration } = performance.getEntriesByName(
              `request-${i + 1}-measure`,
              'measure'
            )[0];

            if (hasError) {
              resolve('Error');
            } else {
              resolve(duration);
            }
          });
      });
    }
  }
};
</script>

<style scoped>
.body {
  margin: 12px 0;
}
.editor {
  height: 200px;
}
.query {
  display: flex;
}
</style>
